<template>
  <div class="bg-white rounded shadow p-2">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">{{ title || "Reset password" }}</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1" @submit.prevent>
      <b-row class="mb-1">
        <b-col cols="12" md="6" lg="4">
          <password-input
            label="Current password"
            name="name"
            v-model="payload.old_password"
          />
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <password-input
            label="New password"
            name="name"
            v-model="payload.password"
          />
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <password-input
            label="New password confirmation"
            name="name"
            v-model="payload.password_confirmation"
          />
        </b-col>
      </b-row>

      <b-row class="px-1">
        <b-button
          class="col-12 btn btn-success btn-xs-block waves-effect waves-float waves-light mb-1"
          @click="onResetPassword"
        >
          Save
        </b-button>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BButton, BCol, BForm, BRow } from "bootstrap-vue";
import PasswordInput from "@/layouts/components/form/PasswordInput";

export default {
  components: {
    PasswordInput,
    BRow,
    BCol,
    BForm,
    BButton,
  },
  props: {
    user: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      payload: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    onResetPassword() {
      this.$emit("save", this.payload);
    },
    resetForm() {
      this.payload = {
        old_password: "",
        password: "",
        password_confirmation: "",
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
