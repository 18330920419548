<template>
  <div>
    <b-input-group>
      <template #prepend>
        <b-input-group-text
          >My EPG XML link:
          <feather-icon
            icon="InfoIcon"
            id="epg-link-info"
            class="text-info info-icon"
            size="23"
            v-b-tooltip.hover="{ variant: 'info', placement: 'bottom' }"
            title="Link usage is limited to 3 IP addresses. This limit gets refreshed every 3 hours"
          />
        </b-input-group-text>
      </template>
      <b-form-input
        @focus="$event.target.select()"
        ref="clone"
        readonly
        :value="epgLink"
      ></b-form-input>
      <template #append>
        <b-button
          id="copy-button"
          @click="copy"
          v-b-tooltip.hover="{ variant: 'success', placement: 'bottom' }"
          title="Copy"
        >
          <feather-icon icon="CopyIcon" />
        </b-button>
      </template>
    </b-input-group>
  </div>
</template>
<script>
import {
  BInputGroup,
  BFormInput,
  BButton,
  BInputGroupText,
} from "bootstrap-vue";
export default {
  components: {
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupText,
  },
  props: {
    epgLink: String,
  },
  methods: {
    copy() {
      this.$refs.clone.focus();
      navigator.clipboard.writeText(this.epgLink);
    },
  },
};
</script>

<style scoped>
.info-icon {
  margin-left: 8px;
}
</style>
