<template>
  <div>
    <user-epg-link
      v-if="userData && userData.services.length > 0 && userData.epg_link"
      :epg-link="userData.epg_link"
    />
    <b-tabs start nav-class="nav-left mt-1">
      <b-tab title="Personal information">
        <b-row class="d-flex justify-content-center">
          <b-col class="col-12 d-flex flex-wrap">
            <profile-form :user="authUser" @save="onSaveProfile">
              <template v-slot:delete-button>
                <b-button
                  variant="outline-danger"
                  class="col-4 mb-1 ml-auto btn-outline-danger btn btn-xs-block waves-effect waves-float waves-light"
                  @click="deleteAccount"
                >
                  Delete account
                </b-button>
              </template>
            </profile-form>
            <subscription-information
              :user="authUser"
            ></subscription-information>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Reset password">
        <b-row class="d-flex justify-content-center">
          <b-col class="col-11">
            <password-reset-form
              ref="passwordResetForm"
              @save="onPasswordReset"
            ></password-reset-form>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="Subscriptions"
        :active="this.$route.params.tabName == 'tabName' ? true : false"
      >
        <b-row class="d-flex justify-content-center">
          <b-col class="col-11">
            <div class="bg-white rounded shadow p-1">
              <table-spinner :loading="subscriptionLoading" />
              <subscription-list-table
                v-if="!subscriptionLoading"
                :data="subscriptions"
                :aft-field="aftField"
                :get-subscription-link="getSubscriptionLink"
                @cancel-click="onCancelSubscription($event)"
              >
              </subscription-list-table>
              <pagination
                :meta-data="subscriptionMeta"
                @change-page="onSubscriptionPageChange($event)"
                @change-per-page="onSubscriptionPerPageChange($event)"
              />
            </div>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SubscriptionInformation from "@/layouts/components/user/SubscriptionInformation.vue";
import ProfileForm from "@/layouts/components/user/ProfileForm.vue";
import { BCol, BRow, BTab, BTabs, BButton } from "bootstrap-vue";
import { mapActions } from "vuex";
import PasswordResetForm from "@/layouts/components/user/PasswordResetForm";
import SubscriptionListTable from "@/layouts/components/subscription/SubscriptionListTable";
import Pagination from "@/layouts/components/XmlTvPagination.vue";
import TableSpinner from "@/layouts/components/TableSpinner";
import { deleteProfile } from "@/services/api/auth";
import UserEpgLink from "@/layouts/components/UserEpgLink.vue";
import Vue from "vue";

export default {
  components: {
    TableSpinner,
    SubscriptionListTable,
    PasswordResetForm,
    SubscriptionInformation,
    BCol,
    BRow,
    BTab,
    BTabs,
    BButton,
    ProfileForm,
    Pagination,
    UserEpgLink,
  },
  data() {
    return {
      aftField: [{ key: "actions", label: "" }],
      userServices: null,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
    subscriptionLoading() {
      return this.$store.getters["authUserSubscription/isLoading"];
    },
    subscriptions() {
      return (
        this.$store.getters["authUserSubscription/getUserSubscriptionData"] ??
        []
      );
    },
    subscriptionMeta() {
      return this.$store.getters[
        "authUserSubscription/getUserSubscriptionMeta"
      ];
    },
    userData() {
      return this.$store.getters["auth/authUser"];
    },
  },
  mounted() {
    this.getUserSubscriptions({
      page: 1,
      perPage: 10,
      sort_by: "status",
      order_by: "asc",
    }).catch(console.error);
  },
  methods: {
    ...mapActions({
      getUserSubscriptions: "authUserSubscription/getUserSubscriptions",
      cancelSubscription: "authUserSubscription/cancelSubscription",
      updateProfile: "auth/updateProfile",
      updatePassword: "auth/updatePassword",
      logout: "auth/logout",
    }),
    getSubscriptionLink(id) {
      return `/profile/subscription/${id}`;
    },
    async onCancelSubscription() {
      try {
        await this.cancelSubscription();
      } catch (e) {
        console.error(e);
      }
    },
    onSubscriptionPageChange(page) {
      this.getUserSubscriptions({
        page,
        perPage: this.subscriptionMeta.per_page,
        sort_by: "status",
        order_by: "asc",
      }).catch(console.error);
    },
    onSubscriptionPerPageChange(perPage) {
      this.getUserSubscriptions({
        page: 1,
        perPage,
        sort_by: "status",
        order_by: "asc",
      }).catch(console.error);
    },
    onSaveProfile(data) {
      try {
        this.updateProfile(data);
        if (data.epg_timezone !== this.authUser.epg_timezone) {
          Vue.$toast.info(
            "Regenerating the EPG XML link may take several minutes after changing the time zone."
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
    async onPasswordReset(payload) {
      await this.updatePassword(payload);
      this.$refs.passwordResetForm.resetForm();
    },
    deleteAccount() {
      this.$swal({
        title: "Deleting account",
        text: `${
          this.authUser.services.length > 0
            ? "You have active services which will also be deleted, and you won't be able to revert this."
            : "You won't be able to revert this."
        }  You will recieve an email with a confirmation link about the deletion attempt, if you click on the link, the deletion will be finalised. Type "delete account" to confirm.`,
        type: "warning",
        icon: "warning",
        input: "text",
        inputPlaceholder: "delete account",
        inputValidator: (value) => {
          if (value != "delete account") {
            return `You need to type "delete account" to confirm.`;
          }
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteProfile();
          this.logout();
          this.$swal({
            position: "top-end",
            icon: "info",
            title: "Account deleting process has started, logging out...",
            showConfirmButton: false,
            timer: 2300,
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
